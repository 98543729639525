<template>
    <div style="height:100%">
        Not Found
    </div>
</template>

<script>
export default {
    methods: {
    },

    created() {
    },

    components: {
    },

    data() {
        return {
        }
    },
}
</script>
